.header-paragraph,
.credits {
  display: block;
  margin-top: 2em;
  text-align: center;
  color: white;
  font-size: 1.5em;
}

.header-paragraph a,
.faq a,
.credits a {
  font-size: 1em;
  display: inline;
  font-family: unset;
}

.cool-text {
  color: #ffa531;
}

.faq {
  display: block;
  margin: 0 auto;
  padding-left: 5em;
  padding-right: 5em;
  text-align: left;
  font-size: 1.5em;
  color: white;
}

.mainpage-header {
  text-align: center;
  font-size: 2.5em;
  color: white;
}
