.language-note {
  text-align: center;
  font-size: 1.7rem;
  color: white;
}

.language-entry {
  text-align: center;
  color: white;
  font-size: 1.6rem;
}
