body,
html {
  width: 100%;
  height: 100%;
  background: black;
  margin: 0;
}

.game {
  border: 1px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: Arial, serif;
  touch-action: none;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: 1;
}

video {
  height: 100% !important;
  position: absolute;
  z-index: -1;
}

a {
  text-align: center;
  font-size: 2em;
  display: block;
  text-decoration: none;
  color: #ffa531;
  font-family: "Courier New", serif;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

#subtitle-container {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  z-index: 10;
  pointer-events: none;
  justify-content: center;
  display: flex;
}

#subtitle {
  text-shadow: 3px 0 0 #000, -3px 0 0 #000, 0 3px 0 #000, 0 -3px 0 #000,
    1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
  text-align: center;
  color: white;
  align-self: flex-end;
  font-size: 2rem;
}

.text-center {
  text-align: center;
  display: block;
}
