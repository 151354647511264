.main-table {
  font-size: 1.5em;
  width: 95%;
  color: white;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
  border-spacing: 0 2em;
}

.main-table h3 {
  text-align: center;
}

@media (min-width: 1900px) {
  .main-table {
    padding: 0 calc(100% / 4) 0 calc(100% / 4);
  }
}

.main-table tr td:first-child {
  width: 20%;
  text-align: center;
  vertical-align: middle;
}

.main-table tr td:nth-child(2) {
  padding-left: 1em;
  text-align: right;
  vertical-align: middle;
}

.main-table p {
  text-align: initial;
}

.control-table {
  margin: 0 auto;
  border: 1px solid white;
  padding: 1em;
  text-align: center;
  vertical-align: middle;
}

.control-table tr td:nth-child(3) {
  text-align: right;
}
.control-table tr td:nth-child(2) {
  text-align: center;
}

.notes-a {
  text-align: unset;
  font-size: unset;
  font-weight: unset;
  display: unset;
  font-family: unset;
}

.browser-notes {
  border: 1px solid white;
  padding: 1em;
}