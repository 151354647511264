.savefile-wrapper {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding-left: 5em;
    padding-right: 5em;
}

.savefile-note {
    text-align: center;
    font-size: 1.7rem;
    color: white;
}

textarea {
    width:70%;
}